import { Tooltip } from '@ui';
import { default as AntButton } from 'antd/es/button';
import { ButtonProps, TooltipProps } from 'antd';
import { CSSObject, Theme } from '@emotion/react';

const Button: React.FunctionComponent<ButtonProps & { placement?: TooltipProps['placement'] }> = ({
  title,
  danger,
  icon,
  size,
  children,
  placement,
  ...props
}) => {
  const renderButton = () => (
    <AntButton
      css={cssButton}
      {...props}
      size={size ?? (icon ? 'middle' : 'large')}
      danger={danger}
      icon={icon}
      children={children}
    />
  );

  return icon ? (
    <Tooltip placement={placement ?? 'bottom'} title={title}>
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export { Button };

const cssButton = (theme: Theme): CSSObject => ({
  '&&': {
    padding: '8px 24px',
    '&&.ant-btn-color-dangerous.ant-btn-variant-outlined': {
      borderColor: theme['color-grey-400'],
    },
    '&&.ant-btn .ant-btn-icon': {
      display: 'flex',
      fontSize: 24,
    },
    '&&.ant-btn-icon-only': {
      padding: 4,
      fontSize: 24,
    },
  },
});
